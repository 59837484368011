import styled from 'styled-components';
import React from 'react'
import Layout from "../components/layout"
import Seo from "../components/seo"

const StackGroup = styled.div`
  width: 100%;
  height: Auto;
  overflow: hidden;
  padding-bottom: 20px;
  color: black;
`;

const StackGrouptwo = styled.div`
  margin: 0;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1em;
  justify-items: center;

  @media (max-width: 1000px) {
    grid-template-columns: repeat(1, 1fr);
    justify-items: center;
    grid-gap: 1em;
  }

  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
    justify-items: center;
    grid-gap: 1em;
  }
`;

const StackGroupthree = styled.div`
  color: black;
  font-weight: 600;
  font-size: 24px;
  padding: 50px 20px 30px 20px;
`;



const StackMini = styled.div`
  width: 100%;
  border-radius: 18px;
  height: auto;
  background: #f5f5f7;

  &:hover {
    background: #ffe701;
  }

  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
`;





const StackTitle = styled.div`
  color: black;
  font-weight: 600;
  font-size: 24px;
  padding: 50px 20px 30px 20px;
`;

const StackTitle2 = styled.div`
  color: black;
  font-weight: 600;
  font-size: 24px;
  padding: 30px 30px 20px 30px;
`;

const StackText2 = styled.div`
  color: black;
  font-weight: 300;
  font-size: 16px;
  align-self: end;
  padding: 0px 30px 60px 30px;
  line-height: 1.8;

  p {
    line-height: 0.8;
  }
`;

const Bonk = styled.div`
  text-align: right;
  width: 100%;
  position: absolute;
  bottom: 0;

  a {
    font-size: 12px;
    padding: 10px;
    margin: 10px;

    background: black;
    border-radius: 18px;
  }

  a {
    color: white;
    display: inline-block;
    position: relative;
    transition: 0.5s;
  }

  a:after {
    content: '»';
    position: absolute;
    opacity: 0;
    right: 0px;
    transition: 0.5s;
  }

  a:hover {
    padding-right: 24px;
    color: black;
    background: #ffe701;
  }

  a:hover:after {
    opacity: 1;
    right: 10px;
  }
`;

const StackAll = styled.div`
  width: 100%;
  min-height: auto;
  position: relative;
  border-radius: 18px;
`;

export default function ContactPage() {
  return (
    <Layout>
    <Seo title="Contact" />
    <div className="Hero">
      <div className="HeroGroup">
      
    <StackAll>
      <StackGroupthree>
        <h2>Collaborative</h2>
        <p>
          A great thing about stuxio network is a collaborative, the way that we
          work with each other
        </p>
      </StackGroupthree>

      <StackGroup>
        <StackGrouptwo>
          <StackMini>
            <StackTitle2>Stuxio Network</StackTitle2>
            <StackText2>
              We are located at Setia Alam, Our Address No.80-2 Jalan Setia
              Utama AS U13/AS Setia Alam, 40170 Shah Alam, Selangor Darul Ehsan
            </StackText2>
          </StackMini>

          <StackMini>
            <StackTitle2>
              Prior to presenting visit or appointment, Please contact us to
              provide you with our exact location
            </StackTitle2>
            <StackText2>
              <p>hello@stuxio.com </p>
              <b>Project Manager</b>
              <p>project@stuxio.com</p>

              <p>intern@stuxio.com</p>
              <p>Studio : +6 03 5879 4613</p>
              <p>WhatsApp : 0125800248</p>
            </StackText2>
          </StackMini>
        </StackGrouptwo>
      </StackGroup>
    </StackAll>
   
    </div>
    </div>
    </Layout>
  );
}
